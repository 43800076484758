import React, { useState, createContext, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Authorizer } from "casbin.js";
import AxiosWrapper from "../Api/http/AxiosWrapper";
import { setAuthUser, setIsLoading } from "../State/actions";
import { toast } from "react-toastify";
import getLoginRequestData from "../Api/request/login";

const { REACT_APP_API_BASE_URL } = process.env;
const authApi = new AxiosWrapper(REACT_APP_API_BASE_URL);

const AuthContext = createContext(null);


/**
 * Transform permissions from API response to a format compatible with casbin.js.
 * @param {Array} permissions - Array of permissions from the API.
 * @returns {Object} - Transformed permissions in the format { action: [resource1, resource2] }.
 */
const transformPermissions = (permissions) => {
  return permissions.reduce((acc, perm) => {
    const actions = perm.action
      .split(" "); // Split into individual actions

    actions.forEach((action) => {
      if (!acc[action]) acc[action] = [];
      acc[action].push(perm.resource);
    });

    return acc;
  }, {});
};

/**
 * Login the user and fetch their permissions.
 * @param {Object} loginData - User login data (e.g., email, password).
 * @param {Function} setLoggedIn - Function to set the logged-in state.
 * @param {Function} setPermissions - Function to set the permissions in context.
 */
const loginUser = async (loginData, setLoggedIn, setPermissions) => {
  try {
    setIsLoading(true);

    // Login request
    const loginRequest = getLoginRequestData();
    const loginResponse = await authApi.post(loginRequest.url, loginData);

    if (!loginResponse?.data?.data?.user) {
      throw new Error("Invalid login response");
    }

    const userData = loginResponse?.data?.data?.user;

    // Fetch permissions
    const permissionsResponse = await authApi.get(`/permissions/${userData.id}`);
    if (!permissionsResponse?.data?.data?.permissions) {
      throw new Error("Invalid permissions response");
    }

    const permissions = permissionsResponse?.data?.data?.permissions;
    const formattedPermissions = transformPermissions(permissions);

    // Debugging: Check if Authorizer exists
    if (!Authorizer) {
      throw new Error("Casbin.js Authorizer is not available");
    }

    // Set permissions in Casbin.js - using correct constructor parameters
    const authorizer = new Authorizer("manual");
    authorizer.setPermission(formattedPermissions);

    // Update state
    setAuthUser(userData);
    setLoggedIn(true);
    setPermissions(authorizer);
    setIsLoading(false);
    toast.success("Login successful");
  } catch (error) {
    setIsLoading(false);
    console.error("Login error:", error);
    setLoggedIn(false);
    if (error?.response?.data?.error?.message !== "FST_JWT_NO_AUTHORIZATION_IN_COOKIE"){
      toast.error(error.message || "Login failed");
    }
  }
};

/**
 * Logout the user and clear their permissions.
 * @param {Function} setLoggedIn - Function to set the logged-in state.
 * @param {Function} setPermissions - Function to clear the permissions in context.
 */
const handleLogout = async (setLoggedIn, setPermissions) => {
  try {
    setIsLoading(true);
    await authApi.get("/logout");
    setLoggedIn(false);
    setPermissions(null); // Clear permissions on logout
    toast.success("Logout successful");
  } catch (error) {
    console.error("Logout error:", error);
    toast.error("Logout failed");
  } finally {
    setIsLoading(false);
  }
};

/**
 * Check if the user is logged in and fetch their permissions.
 * @param {Function} setLoggedIn - Function to set the logged-in state.
 * @param {Function} setPermissions - Function to set the permissions in context.
 */
const checkLogin = async (setLoggedIn, setPermissions) => {
  try {
    setIsLoading(true);
    const loginRequest = getLoginRequestData();
    const checkLoginResponse = await authApi.get(loginRequest.url);

    if (!checkLoginResponse?.data?.data?.user) {
      throw new Error("Invalid check login response");
    }

    const isLoggedInStatus = checkLoginResponse.data.data.isLoggedIn;
    const userData = checkLoginResponse.data.data.user;

    // Fetch permissions
    const permissionsResponse = await authApi.get(`/permissions/${userData.id}`);
    if (!permissionsResponse?.data?.data?.permissions) {
      throw new Error("Invalid permissions response");
    }

    const permissions = permissionsResponse.data.data.permissions;
    const formattedPermissions = transformPermissions(permissions);

    // Debugging: Check if Authorizer exists
    if (!Authorizer) {
      throw new Error("Casbin.js Authorizer is not available");
    }

    // Set permissions in Casbin.js - Fix constructor parameters
    const authorizer = new Authorizer("manual");
    authorizer.setPermission(formattedPermissions);

    // Update state
    setAuthUser(userData);
    setLoggedIn(isLoggedInStatus);
    setPermissions(authorizer);
  } catch (error) {
    console.error("Check login error:", error);
    setLoggedIn(false);
    if (error?.response?.data?.error?.message !== "FST_JWT_NO_AUTHORIZATION_IN_COOKIE"){
      toast.error(error.message || "Login failed");
    }
  } finally {
    setIsLoading(false);
  }
};

/**
 * AuthProvider component to manage authentication state and permissions.
 * @param {Object} props - Component props.
 * @param {ReactNode} props.children - Child components.
 */
const AuthProvider = ({ children }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [loggedIn, setLoggedInState] = useState(isLoggedIn);
  const [permissions, setPermissions] = useState(null);

  // Update logged-in state in localStorage
  const setLoggedIn = (isLoggedInState) => {
    localStorage.setItem("isLoggedIn", isLoggedInState ? "true" : "false");
    setLoggedInState(isLoggedInState);
  };

  // Handle successful login
  const handleSuccessLogin = async (response) => {
    await loginUser(response, setLoggedIn, setPermissions);
  };

  // Handle login errors
  const handleErrorLogin = (error) => {
    console.error("Login error:", error);
    setLoggedIn(false);
    toast.error("Login failed, try again later");
  };

  // Check login status on mount
  useEffect(() => {
    checkLogin(setLoggedIn, setPermissions);
  }, []);

  // Memoize context value to avoid unnecessary re-renders
  const authContextValue = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
      permissions,
      handleSuccessLogin,
      handleErrorLogin,
      handleLogout: () => handleLogout(setLoggedIn, setPermissions),
      checkLogin: () => checkLogin(setLoggedIn, setPermissions),
    }),
    [loggedIn, permissions]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
