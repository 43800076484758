import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setProjects,
  setSelectedProject,
  setOpenProjectDialogForm as setOpenDialog,
  setProjectDeleteSuccess,
  setIsLoading,
  setUsers,
  setProjectDialogInfo,
} from "../../../State/actions";
import EditIcon from "../../../Components/Icons/EditIcon.svg";
import DeleteIcon from "../../../Components/Icons/DeleteIcon.svg";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import "./ProjectManagementPage.css";
import DataTable from "../../../Components/DataGrid/DataGrid";
import AxiosWrapper from "../../../Api/http/AxiosWrapper";
import getProjectsData from "../../../Api/request/projects";
import { fetchUsers } from "../UserManagementPage/UserManagementPage";
import { useAuth } from "../../../Hooks/useAuth";
import permissionConfig from "../../../config/permissionsConfig.json";

const { REACT_APP_API_BASE_URL } = process.env;
const projectsApi = new AxiosWrapper(REACT_APP_API_BASE_URL);

const dialogFormInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    id: "vertical",
    label: "Vertical",
    type: "select",
    selectedValue: "LEAD",
    options: [
      { value: "App Mod", label: "App Mod" },
      { value: "Data Mod", label: "Data Mod" },
      { value: "Infra Mod", label: "Infra Mod" },
    ],
  },
  {
    id: "status",
    label: "Status",
    type: "select",
    selectedValue: "ACTIVE",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "In active" },
    ],
  },
  {
    id: "jenkins_url",
    label: "Jenkins Base Url",
    type: "text",
    required: true,
  },
  {
    id: "jenkins_username",
    label: "Jenkins Username",
    type: "text",
    required: true,
  },
  {
    id: "jenkins_token",
    label: "Jenkins Token",
    type: "password",
    required: true,
  },
  {
    id: "approvers_list",
    label: "Approvers List",
    type: "select",
    isMultiple: true,
    required: true,
    selectedValue: "",
    options: [],
  },
];

export const fetchProjects = async (
  page,
  pageSize,
  setProjectsState,
  setPaginationModel,
  searchValue = ""
) => {
  try {
    setIsLoading(true);
    const projectsRequestData = getProjectsData();
    const response = await projectsApi.get(
      `${projectsRequestData.url}?page=${page}&pageSize=${pageSize}&q=${searchValue}`
    );

    if (!response?.data?.data) {
      throw new Error("Invalid response from server");
    }

    const { projects = [], pagination = {} } = response.data.data;

    setProjectsState(projects);
    if (setPaginationModel) {
      setPaginationModel((prev) => ({
        ...prev,
        ...pagination,
      }));
    }
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

const createProject = async (
  selectedProject,
  projectsState,
  setProjectsState
) => {
  try {
    setIsLoading(true);
    const projectsRequestData = getProjectsData();
    const response = await projectsApi.post(
      projectsRequestData.url,
      selectedProject
    );
    const project = response?.data?.data?.project;
    setProjectsState([...projectsState, project]);
    toast.success(response?.data?.message || "Project created successfully");
    setOpenDialog(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

const updateProject = async (
  selectedProject,
  projectsState,
  setProjectsState
) => {
  try {
    setIsLoading(true);
    const projectsRequestData = getProjectsData();
    const id = selectedProject?.id;
    const response = await projectsApi.put(
      `${projectsRequestData.url}/${id}`,
      selectedProject
    );
    const project = response?.data?.data?.project;
    const newState = projectsState?.map((projectState) => {
      if (projectState.id === selectedProject.id) return project;
      else return projectState;
    });
    setProjectsState(newState);
    toast.success(response?.data?.message || "Project updated successfully");
    setOpenDialog(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

const deleteProject = async (id) => {
  try {
    setIsLoading(true);
    const projectsRequestData = getProjectsData();
    await projectsApi.delete(`${projectsRequestData.url}/${id}`);
    setProjectDeleteSuccess(id);
    toast.success("Project deleted successfully");
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

const ProjectManagement = () => {
  const projectsState = useSelector((state) => state?.project?.projects);
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const openDialog = useSelector((state) => state?.project?.openDialog);
  const dialogInfo = useSelector((state) => state?.project?.dialogInfo);
  const usersState = useSelector((state) => state?.user?.users);
  const [rows, setRows] = useState([]);
  const [formInputs, setFormInputs] = useState(dialogFormInputs);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
    totalItems: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [canEditProject, setCanEditProject] = useState(false);
  const [canDeleteProject, setCanDeleteProject] = useState(false);
  const [canAddProject, setCanAddProject] = useState(false);
  const { permissions } = useAuth();

  useEffect(() => {
    const resolvePermissions = async () => {
      const canEdit = await permissions?.can(
        permissionConfig.projects.edit.action,
        permissionConfig.projects.edit.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      const canDelete = await permissions?.can(
        permissionConfig.projects.delete.action,
        permissionConfig.projects.delete.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      const canAdd = await permissions?.can(
        permissionConfig.projects.create.action,
        permissionConfig.projects.create.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      setCanEditProject(canEdit);
      setCanDeleteProject(canDelete);
      setCanAddProject(canAdd);
    };
    resolvePermissions();
  }, [permissions]);

  const handleAddProject = () => {
    setSelectedProject({
      name: "",
      vertical: "App Mod",
      status: "ACTIVE",
      jenkins_url: "",
      jenkins_username: "",
      jenkins_token: "",
      approvers_list: [],
    });
    setProjectDialogInfo({
      actionType: "add",
      title: "Add Project",
      contentText: "Enter new project details below",
      dialogType: "submit",
    });
    setOpenDialog(true);
  };

  console.log("searchValue", searchValue);

  const handleRowEdit = (id, row) => () => {
    setSelectedProject(row);
    setProjectDialogInfo({
      actionType: "edit",
      title: "Edit Project",
      contentText: "Update project details below",
      dialogType: "submit",
    });
    setOpenDialog(true);
  };

  const handleRowDelete = (id) => () => {
    setProjectDialogInfo({
      actionType: "delete",
      title: "Delete Confirmation",
      contentText: "Are you sure you want to delete this project?",
      dialogType: "confirm",
      actions: [
        {
          label: "Cancel",
          onClick: () => setOpenDialog(false),
          className: "dialog-button-cancel",
        },
        {
          label: "Delete",
          onClick: async () => {
            await deleteProject(id);
            await fetchProjects(
              paginationModel.page,
              paginationModel.pageSize,
              setProjects,
              setPaginationModel
            );
            setOpenDialog(false);
          },
          className: "dialog-button-delete",
        },
      ],
    });
    setOpenDialog(true);
  };

  const handleFormSubmit = async () => {
    if (!selectedProject.id) {
      await createProject(selectedProject, projectsState, setProjects);
    } else {
      await updateProject(selectedProject, projectsState, setProjects);
    }
    await fetchProjects(
      paginationModel.page,
      paginationModel.pageSize,
      setProjects,
      setPaginationModel
    );
  };
  const columnsArr = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "vertical", headerName: "Vertical", width: 150 },
    { field: "status", headerName: "Status" },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const actions = [];

        if (canEditProject) {
          actions.push(
            <GridActionsCellItem
              icon={<img src={EditIcon} alt="Edit Icon" />}
              label="Edit"
              className="textPrimary"
              onClick={handleRowEdit(id, row)}
              color="inherit"
              key="edit"
            />
          );
        }

        if (canDeleteProject) {
          actions.push(
            <GridActionsCellItem
              icon={<img src={DeleteIcon} alt="Delete Icon" />}
              label="Delete"
              onClick={handleRowDelete(id)}
              color="inherit"
              key="delete"
            />
          );
        }

        return actions;
      },
    },
  ];

  // Only fetch projects data on initial load and pagination changes
  useEffect(() => {
    fetchProjects(
      paginationModel.page,
      paginationModel.pageSize,
      setProjects,
      setPaginationModel,
      searchValue
    );
  }, [paginationModel.page, paginationModel.pageSize, searchValue]);

  // Fetch users data only when dialog opens for add/edit
  useEffect(() => {
    const shouldFetchUsers = openDialog && (dialogInfo?.actionType === "edit" || dialogInfo?.actionType === "add");
    if (shouldFetchUsers) {
      const fetchUsersData = async () => {
        try {
          setIsLoading(true);
          await fetchUsers(1, 10000, setUsers);
        } catch (error) {
          console.error(error?.message);
          toast.error(error?.response?.data?.error?.message || error?.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchUsersData();
    }
  }, [openDialog, dialogInfo?.actionType]);

  // Update form inputs when users data is available
  useEffect(() => {
    const userOptions = usersState?.map((user) => ({
      value: user?.id,
      label: user?.email,
    }));

    const newFormInputs = [...dialogFormInputs];
    const approversInputIndex = newFormInputs.findIndex(
      (input) => input.id === "approvers_list"
    );

    if (approversInputIndex !== -1) {
      newFormInputs[approversInputIndex] = {
        ...newFormInputs[approversInputIndex],
        options: userOptions,
      };
      setFormInputs(newFormInputs);
    }
  }, [usersState]);

  useEffect(() => {
    setRows(projectsState);
  }, [projectsState]);

  const handlePaginationChange = (data) =>
    setPaginationModel({ page: data?.page + 1, pageSize: data?.pageSize });

  return (
    <div className="project-management-container">
      <DataTable
        rows={rows}
        columns={columnsArr}
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 15]}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        formInputs={formInputs}
        formState={selectedProject}
        setFormState={setSelectedProject}
        handleFormSubmit={handleFormSubmit}
        enableAddRecord={true}
        title={dialogInfo?.title}
        dialogInfo={dialogInfo}
        buttonText="Add Project"
        dialogContentText={dialogInfo?.contentText}
        onAddClick={handleAddProject}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handlePaginationChange={handlePaginationChange}
        enableAddButton={canAddProject}
      />
    </div>
  );
};

export default ProjectManagement;
