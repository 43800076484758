import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setPipelines,
  setSelectedPipeline,
  setOpenPipelineDialogForm as setOpenDialog,
  setPipelineDeleteSuccess,
  setIsLoading,
  setProjects,
  setUsers,
  setPipelineDialogInfo,
} from "../../../State/actions";

import { fetchProjects } from "../ProjectManagementPage/ProjectManagementPage";
import { fetchUsers } from "../UserManagementPage/UserManagementPage";
import EditIcon from "../../../Components/Icons/EditIcon.svg";
import DeleteIcon from "../../../Components/Icons/DeleteIcon.svg";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import "./pipelineManagement.css";
import DataTable from "../../../Components/DataGrid/DataGrid";
import AxiosWrapper from "../../../Api/http/AxiosWrapper";
import getPipelinesData from "../../../Api/request/pipelines";
import { useAuth } from "../../../Hooks/useAuth";
import permissionConfig from "../../../config/permissionsConfig.json";

const { REACT_APP_API_BASE_URL } = process.env;
const api = new AxiosWrapper(REACT_APP_API_BASE_URL);

const dialogFormInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    id: "environment",
    label: "Environment",
    type: "text",
    required: true,
  },
  {
    id: "job_id",
    label: "Jenkins Job Id",
    type: "text",
    required: true,
  },
  {
    id: "status",
    label: "Status",
    type: "select",
    selectedValue: "ACTIVE",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "In active" },
    ],
  },
  {
    id: "project_id",
    label: "Project",
    type: "select",
    selectedValue: "",
    options: [],
    required: true,
  },
  {
    id: "approver_id",
    label: "Approver",
    type: "select",
    isMultiple: true,
    selectedValue: "",
    options: [],
    required: true,
  },
];

const PipelineManagement = () => {
  const pipelinesState = useSelector((state) => state?.pipeline?.pipelines);
  const projectsState = useSelector((state) => state?.project?.projects);
  const usersState = useSelector((state) => state?.user?.users);
  const selectedPipeline = useSelector(
    (state) => state.pipeline.selectedPipeline
  );
  const selectedProjectId = useSelector(
    (state) => state.pipeline.selectedProjectId
  );
  const openDialog = useSelector((state) => state.pipeline.openDialog);
  const dialogInfo = useSelector((state) => state.pipeline.dialogInfo);

  const [rows, setRows] = useState(pipelinesState);
  const [formInputs, setFormInputs] = useState(dialogFormInputs);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
    totalItems: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [canEditPipeline, setCanEditPipeline] = useState(false);
  const [canDeletePipeline, setCanDeletePipeline] = useState(false);
  const [canAddPipeline, setCanAddPipeline] = useState(false);
  const { permissions } = useAuth();

  useEffect(() => {
    const resolvePermissions = async () => {
      const canEdit = await permissions?.can(
        permissionConfig.pipelines.edit.action,
        permissionConfig.pipelines.edit.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      const canDelete = await permissions?.can(
        permissionConfig.pipelines.delete.action,
        permissionConfig.pipelines.delete.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      const canAdd = await permissions?.can(
        permissionConfig.pipelines.create.action,
        permissionConfig.pipelines.create.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      setCanAddPipeline(canAdd);
      setCanEditPipeline(canEdit);
      setCanDeletePipeline(canDelete);
    };
    resolvePermissions();
  }, [permissions]);
  const handleAddPipeline = () => {
    setSelectedPipeline({
      name: "",
      environment: "",
      status: "ACTIVE",
      job_id: "",
      project_id: "",
      approver_id: [],
    });
    setPipelineDialogInfo({
      actionType: "add",
      title: "Add Pipeline",
      contentText: "Enter new pipeline details below",
      dialogType: "submit",
    });
    setOpenDialog(true);
  };

  const handleRowEdit = (id, row) => () => {
    setSelectedPipeline(row);
    setPipelineDialogInfo({
      actionType: "edit",
      title: "Edit Pipeline",
      contentText: "Update pipeline details below",
      dialogType: "submit",
    });
    setOpenDialog(true);
  };

  const handleRowDelete = (id) => () => {
    setPipelineDialogInfo({
      actionType: "delete",
      title: "Delete Confirmation",
      contentText: "Are you sure you want to delete this pipeline?",
      dialogType: "confirm",
      actions: [
        {
          label: "Cancel",
          onClick: () => setOpenDialog(false),
          className: "dialog-button-cancel",
        },
        {
          label: "Delete",
          onClick: async () => {
            await deletePipeline(id);
            await fetchPipelines(
              paginationModel.page,
              paginationModel.pageSize,
              setPipelines,
              setPaginationModel
            );
            setOpenDialog(false);
          },
          className: "dialog-button-delete",
        },
      ],
    });
    setOpenDialog(true);
  };

  const handleFormSubmit = async () => {
    if (!selectedPipeline.id) {
      await createPipeline(selectedPipeline, pipelinesState, setPipelines);
    } else {
      await updatePipeline(selectedPipeline, pipelinesState, setPipelines);
    }
    await fetchPipelines(
      paginationModel.page,
      paginationModel.pageSize,
      setPipelines,
      setPaginationModel
    );
  };
  const columnsArr = [
    { field: "id", headerName: "ID", width: 250 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "project_name", headerName: "Project", width: 100 },
    { field: "approver_names", headerName: "Approver", width: 100 },
    { field: "environment", headerName: "Environment", width: 100 },
    { field: "status", headerName: "Status", width: 50 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const actions = [];

        if (canEditPipeline) {
          actions.push(
            <GridActionsCellItem
              icon={<img src={EditIcon} alt="Edit Icon" />}
              label="Edit"
              className="textPrimary"
              onClick={handleRowEdit(id, row)}
              color="inherit"
              key="edit"
            />
          );
        }

        if (canDeletePipeline) {
          actions.push(
            <GridActionsCellItem
              icon={<img src={DeleteIcon} alt="Delete Icon" />}
              label="Delete"
              onClick={handleRowDelete(id)}
              color="inherit"
              key="delete"
            />
          );
        }

        return actions;
      },
    },
  ];

  const LOCKED_FIELDS = ["name", "project_id"];
  const disabledDialogFormInputs = formInputs?.map((input) => ({
    ...input,
    disabled: LOCKED_FIELDS.includes(input?.id),
  }));

  const handlePaginationChange = (data) =>
    setPaginationModel({ page: data?.page + 1, pageSize: data?.pageSize });

  useEffect(() => {
    // Only fetch pipelines on initial load
    fetchPipelines(
      paginationModel.page,
      paginationModel.pageSize,
      setPipelines,
      setPaginationModel,
      searchValue,
    );
  }, [paginationModel.page, paginationModel.pageSize, searchValue]);

  // fetch projects and users only when dialog opens for add/edit
  useEffect(() => {
    const shouldFetchData = openDialog && (dialogInfo?.actionType === "edit" || dialogInfo?.actionType === "add");
    if (shouldFetchData) {
      // Only fetch projects and users when dialog opens for add/edit
      const fetchProjectsAndUsers = async () => {
        try {
          setIsLoading(true);
          await Promise.all([
            fetchProjects(1, 1000, setProjects),
            fetchUsers(1, 1000, setUsers),
          ]);
        } catch (error) {
          console.error(error?.message);
          toast.error(error?.response?.data?.message || error?.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchProjectsAndUsers();
    }
  }, [openDialog, dialogInfo?.actionType]);

  useEffect(() => {
    const projectOptions = projectsState
      ?.filter((project) => project?.status === "ACTIVE")
      ?.map((project) => ({
        value: project?.id,
        label: project?.name,
      }));

    const selectedProjectObject = projectsState.find(
      (project) => project.id === selectedProjectId
    );

    const userOptions = usersState
      ?.filter((user) =>
        selectedProjectObject?.approvers_list?.includes(user?.id)
      )
      .map((user) => ({
        value: user?.id,
        label: user?.email,
      }));

    const dialogFormInputsLength = dialogFormInputs.length;
    const newDialogFormInputs = [...dialogFormInputs];
    newDialogFormInputs[dialogFormInputsLength - 1].options = userOptions;
    newDialogFormInputs[dialogFormInputsLength - 2].options = projectOptions;
    setFormInputs(newDialogFormInputs);
  }, [projectsState, usersState, selectedProjectId]);

  useEffect(() => {
    setRows(pipelinesState);
  }, [pipelinesState, projectsState, usersState]);

  return (
    <div className="about-container">
      <DataTable
        rows={rows}
        columns={columnsArr}
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 15, 50, 100]}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        formInputs={
          selectedPipeline?.id ? disabledDialogFormInputs : dialogFormInputs
        }
        formState={selectedPipeline}
        setFormState={setSelectedPipeline}
        handleFormSubmit={handleFormSubmit}
        enableAddRecord={true}
        title={dialogInfo?.title}
        dialogInfo={dialogInfo}
        buttonText="Add Pipeline"
        dialogContentText={dialogInfo?.contentText}
        onAddClick={handleAddPipeline}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handlePaginationChange={handlePaginationChange}
        enableAddButton={canAddPipeline}
      />
    </div>
  );
};

export const fetchPipelines = async (
  page,
  pageSize,
  setPipelinesState,
  setPaginationModel,
  searchValue = ""
) => {
  try {
    setIsLoading(true);
    const pipelinesRequestData = getPipelinesData();
    const response = await api.get(
      `${pipelinesRequestData.url}?page=${page}&pageSize=${pageSize}&q=${searchValue}`
    );

    if (!response?.data?.data) {
      throw new Error("Invalid response from server");
    }

    const { pipelines = [], pagination = {} } = response.data.data;

    setPipelinesState(pipelines);
    if (setPaginationModel) {
      setPaginationModel((prev) => ({
        ...prev,
        ...pagination,
      }));
    }
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

const createPipeline = async (
  selectedPipeline,
  pipelinesState,
  setPipelinesState
) => {
  try {
    setIsLoading(true);
    const pipeliensRequestData = getPipelinesData();
    const response = await api.post(pipeliensRequestData.url, selectedPipeline);
    const pipeline = response?.data?.data?.pipeline;
    setPipelinesState([...pipelinesState, pipeline]);
    toast.success(response?.data?.message || "Pipeline Created successfully");
    setOpenDialog(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

const updatePipeline = async (
  selectedPipeline,
  pipelinesState,
  setPipelinesState
) => {
  try {
    setIsLoading(true);
    const pipeliensRequestData = getPipelinesData();
    const id = selectedPipeline?.id;
    const response = await api.put(
      `${pipeliensRequestData.url}/${id}`,
      selectedPipeline
    );
    const pipeline = response?.data?.data?.pipeline;
    const newState = pipelinesState?.map((pipelineState) => {
      if (pipelineState.id === selectedPipeline.id) return pipeline;
      else return pipelineState;
    });
    setPipelinesState(newState);
    toast.success(response?.data?.message || "Pipeline updated successfully");
    setOpenDialog(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

const deletePipeline = async (id) => {
  try {
    setIsLoading(true);
    const pipelinesRequestData = getPipelinesData();
    await api.delete(`${pipelinesRequestData.url}/${id}`);
    setPipelineDeleteSuccess(id);
    toast.success("Pipeline deleted successfully");
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

export default PipelineManagement;
