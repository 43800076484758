import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

import Backdrop from "@mui/material/Backdrop";

export default function CustomLoader() {
  const isLoading = useSelector((state) => state?.loader?.isLoading);

  if (!isLoading) {
    return null;
  }

  return (
    <div>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 10000 })}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
