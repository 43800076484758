import React from "react";

import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

// import AddIcon from "@mui/icons-material/Add";
import FormDialog from "../FormDialog/FormDialog";

import { GridToolbarContainer, DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import { Button, Input } from "@mui/joy";
import SearchIcon from "@mui/icons-material/Search";

function EditToolbar({
  onAddClick,
  buttonText,
  searchValue,
  setSearchValue,
  enableAddButton,
}) {
  return (
    <GridToolbarContainer sx={{ width: "100%" }}>
      <Stack
        sx={{ justifyContent: "space-between", margin: "20px", width: "100%" }}
        direction="row"
      >
        <Input
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ width: 300 }}
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
        />

        {enableAddButton && (
          <Button
            sx={{
              bgcolor: "#29B475",
              color: "black",
              fontWeight: "400",
              border: "1px solid black",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                bgcolor: "#218F5C",
                color: "white",
                borderColor: "white",
              },
            }}
            onClick={() => {
              onAddClick();
            }}
          >
            {buttonText}
          </Button>
        )}
      </Stack>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  onAddClick: PropTypes.func,
  buttonText: PropTypes.string,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  enableAddButton: PropTypes.bool,
};

/* eslint-disable max-lines-per-function */
export default function DataTable({
  rows,
  columns,
  paginationModel,
  pageSizeOptions,
  formInputs,
  buttonText,
  title,
  dialogcontentText,
  formState,
  setFormState,
  handleFormSubmit,
  enableAddRecord,
  openDialog,
  setOpenDialog,
  searchValue,
  setSearchValue,
  handlePaginationChange,
  dialogInfo,
  onAddClick,
  enableAddButton
}) {
  // const [opendialog, setOpenDialog] = useState(false);

  return (
    <Box sx={{ padding: "24px", borderRadius: "20px", bgcolor: "white" }}>
      <Paper sx={{ height: 456, width: "100%", borderRadius: "12px" }}>
        <FormDialog
          dialogInfo={dialogInfo}
          open={openDialog}
          setOpen={setOpenDialog}
          formInputs={formInputs}
          title={title}
          contentText={dialogcontentText}
          formState={formState}
          setFormState={setFormState}
          handleFormSubmit={handleFormSubmit}
        />
        <DataGrid
          rows={rows}
          columns={columns.map((col) => ({
            ...col,
            flex: col.flex || 1,
          }))}
          onRowClick={(params) => {
            setFormState(params.row);
          }}
          disableColumnMenu={true}
          initialState={{
            pagination: {
              paginationModel: { page: paginationModel?.page - 1, pageSize: 5 },
            },
          }}
          rowCount={paginationModel?.totalItems}
          pageSizeOptions={pageSizeOptions}
          onPaginationModelChange={handlePaginationChange}
          paginationMode="server"
          // checkboxSelection
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "16.8px",
            letterSpacing: "0px",
            borderRadius: "12px",
            border: 0,
            "& .MuiDataGrid-main": {
              borderRadius: "12px",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#2A3749",
              color: "#fff",
            },
            "& .MuiDataGrid-footerContainer": {
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
              backgroundColor: "#f4f4f4",
            },
            "& .MuiDataGrid-row": {
              "&:nth-of-type(odd)": {
                backgroundColor: "#2A374908",
                color: "#061425",
              },
              "&:nth-of-type(even)": {
                backgroundColor: "#ffffff",
                color: "#000",
              },
              "&:hover": {
                backgroundColor: "#2A374919",
              },
            },
            "& .MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root":
              {
                width: "80px",
                height: "32px",
                border: "1px solid #29B475",
                margin: "0 4px",
                padding: "3px 10px",
                borderRadius: "8px",
                fontSize: "14px",
                color: "#29B475",
                "&.Mui-disabled": {
                  borderColor: "grey.300",
                  color: "grey.300",
                },
                "& .MuiSvgIcon-root": {
                  display: "none", // Hide the default arrow icons
                },
                "&[aria-label='Go to previous page']::before": {
                  content: '"< Prev"',
                  display: "block",
                },
                "&[aria-label='Go to next page']::before": {
                  content: '"Next >"',
                  display: "block",
                },
              },
          }}
          slots={{
            toolbar: enableAddRecord ? EditToolbar : null,
          }}
          slotProps={{
            toolbar: enableAddRecord
              ? {
                  onAddClick,
                  buttonText,
                  searchValue,
                  setSearchValue,
                  enableAddButton,
                }
              : null,
          }}
        />
      </Paper>
    </Box>
  );
}

DataTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  paginationModel: PropTypes.object,
  pageSizeOptions: PropTypes.array,

  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  formInputs: PropTypes.array,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  dialogcontentText: PropTypes.string,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  enableAddRecord: PropTypes.bool,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  handlePaginationChange: PropTypes.func,
  dialogInfo: PropTypes.object,
  onAddClick: PropTypes.func,
  enableAddButton: PropTypes.bool,
};
