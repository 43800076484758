import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  setOpenRolesDialogForm as setOpenDialog,
  setIsLoading,
  setRoles,
  setSelectedRole,
  setRoleDialogInfo,
} from "../../../State/actions";
import permissionConfig from "../../../config/permissionsConfig.json";
import { toast } from "react-toastify";
import DataTable from "../../../Components/DataGrid/DataGrid";
import AxiosWrapper from "../../../Api/http/AxiosWrapper";
import EditIcon from "../../../Components/Icons/EditIcon.svg";
import DeleteIcon from "../../../Components/Icons/DeleteIcon.svg";
import getRolesData from "../../../Api/request/roles";
import { GridActionsCellItem } from "@mui/x-data-grid";
import getPermissionsData from "../../../Api/request/permissions";
import { useAuth } from "../../../Hooks/useAuth";

const { REACT_APP_API_BASE_URL } = process.env;
const rolesAPI = new AxiosWrapper(REACT_APP_API_BASE_URL);

const dialogFormInputs = [
  {
    id: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    id: "description",
    label: "Description",
    type: "text",
    required: true,
    disabled: false,
  },
  {
    id: "status",
    label: "Status",
    type: "select",
    selectedValue: "ACTIVE",
    options: [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "In active" },
      { value: "DISABLED", label: "disabled" },
    ],
  },
  {
    id: "permissions",
    label: "Permissions",
    type: "select",
    selectedValue: "",
    options: [],
    required: true,
    disabled: false,
    isMultiple: true,
  },
];

const RolesTable = () => {
  const { roles, selectedRole, openDialog, dialogInfo } = useSelector(
    (state) => state.roles
  );
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 5,
    totalItems: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [canEditRole, setCanEditRole] = useState(false);
  const [canDeleteRole, setCanDeleteRole] = useState(false);
  const [canAddRole, setCanAddRole] = useState(false);
  const { permissions } = useAuth();

  const handlePaginationChange = (data) =>
    setPaginationModel({ page: data?.page + 1, pageSize: data?.pageSize });

  const handleAddRole = () => {
    setSelectedRole({
      name: "",
      description: "",
      status: "ACTIVE",
      permissions: [],
    });
    setRoleDialogInfo({
      actionType: "add",
      title: "Add Role",
      contentText: "Enter new role details below",
      dialogType: "submit",
    });
    setOpenDialog(true);
  };

  const handleRowEdit = (id, row) => () => {
    console.log("row", row);
    setSelectedRole(row);
    setRoleDialogInfo({
      actionType: "edit",
      title: "Edit Role",
      contentText: "",
      dialogType: "submit",
    });
    setOpenDialog(true);
  };

  const handleRowDelete = (id) => () => {
    setRoleDialogInfo({
      actionType: "delete",
      title: "Delete Confirmation",
      contentText: "Are you sure you want to delete this role?",
      dialogType: "confirm",
      actions: [
        {
          label: "Cancel",
          onClick: () => setOpenDialog(false),
          className: "dialog-button-cancel",
        },
        {
          label: "Delete",
          onClick: async () => {
            await deleteRole(
              id,
              paginationModel.page,
              paginationModel.pageSize,
              setPaginationModel
            );
            setOpenDialog(false);
          },
          className: "dialog-button-delete",
        },
      ],
    });
    setOpenDialog(true);
  };

  const handleFormSubmit = async () => {
    if (!selectedRole.id) {
      await createRole(selectedRole);
    } else {
      await updateRole(selectedRole);
    }
    await fetchRoles(
      paginationModel.page,
      paginationModel.pageSize,
      setRoles,
      setPaginationModel
    );
  };

 console.log("permissions", permissions);

  const columnsArr = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "description", headerName: "Description", width: 500 },
    { field: "status", headerName: "Status" },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const actions = [];

        if (canEditRole) {
          actions.push(
            <GridActionsCellItem
              icon={<img src={EditIcon} alt="Edit Icon" />}
              label="Edit"
              className="textPrimary"
              onClick={handleRowEdit(id, row)}
              color="inherit"
              key="edit"
            />
          );
        }

        if (canDeleteRole) {
          actions.push(
            <GridActionsCellItem
              icon={<img src={DeleteIcon} alt="Delete Icon" />}
              label="Delete"
              onClick={handleRowDelete(id)}
              color="inherit"
              key="delete"
            />
          );
        }

        return actions;
      },
    },
  ];

  useEffect(() => {
    fetchRoles(
      paginationModel.page,
      paginationModel.pageSize,
      setRoles,
      setPaginationModel,
      searchValue
    );
    fetchPermissions();
  }, [paginationModel.page, paginationModel.pageSize, searchValue]);
  useEffect(() => {
    const resolvePermissions = async () => {
      const canEdit = await permissions?.can(
        permissionConfig.roles.edit.action,
        permissionConfig.roles.edit.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      const canDelete = await permissions?.can(
        permissionConfig.roles.delete.action,
        permissionConfig.roles.delete.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      const canAdd = await permissions?.can(
        permissionConfig.roles.create.action,
        permissionConfig.roles.create.resource
      ) || await permissions?.can(
        permissionConfig.admin.action,
        permissionConfig.admin.resource
      );
      setCanEditRole(canEdit);
      setCanDeleteRole(canDelete);
      setCanAddRole(canAdd);
    };
    resolvePermissions();
  }, [permissions]);

  return (
    <div className="roles-management-container">
      <DataTable
        rows={roles}
        columns={columnsArr}
        paginationModel={paginationModel}
        pageSizeOptions={[5, 10, 15]}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        formInputs={dialogFormInputs}
        formState={selectedRole}
        setFormState={setSelectedRole}
        handleFormSubmit={handleFormSubmit}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        enableAddRecord={true}
        title={dialogInfo?.title}
        dialogInfo={dialogInfo}
        buttonText="Add Role"
        dialogContentText={dialogInfo?.contentText}
        handlePaginationChange={handlePaginationChange}
        onAddClick={handleAddRole}
        enableAddButton={canAddRole}
      />
    </div>
  );
};

export default RolesTable;

// API Calls
export const fetchPermissions = async () => {
  try {
    setIsLoading(true);
    const permissionsRequestData = getPermissionsData();
    const response = await rolesAPI.get(permissionsRequestData.url);
    const permissions = response?.data?.data?.permissions;

    const formattedPermissions = permissions.map((permission) => ({
      value: permission.id,
      label: permission.name,
      description: permission.description,
    }));

    const permissionsInput = dialogFormInputs.find(
      (input) => input.id === "permissions"
    );
    if (permissionsInput) {
      permissionsInput.options = formattedPermissions;
    }

    setIsLoading(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
    setIsLoading(false);
  }
};

export const fetchRoles = async (
  page,
  pageSize,
  setRolesState,
  setPaginationModel,
  searchValue = ""
) => {
  try {
    setIsLoading(true);
    const rolesRequestData = getRolesData();
    const response = await rolesAPI.get(
      `${rolesRequestData.url}?page=${page}&pageSize=${pageSize}&q=${searchValue}`
    );

    if (!response?.data?.data) {
      throw new Error("Invalid response from server");
    }

    const { roles = [], pagination = {} } = response.data.data;

    setRolesState(roles);
    if (setPaginationModel) {
      setPaginationModel((prev) => ({
        ...prev,
        ...pagination,
      }));
    }
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

export const createRole = async (selectedRole) => {
  try {
    setIsLoading(true);
    const rolesRequestData = getRolesData();
    const response = await rolesAPI.post(rolesRequestData.url, selectedRole);
    toast.success(response?.data?.data?.message || "Role Created Successfully");
    setOpenDialog(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

export const updateRole = async (selectedRole) => {
  try {
    setIsLoading(true);
    const rolesRequestData = getRolesData();
    const id = selectedRole?.id;
    const response = await rolesAPI.put(
      `${rolesRequestData.url}/${id}`,
      selectedRole
    );
    toast.success(response?.data?.data?.message || "Role updated successfully");
    setOpenDialog(false);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};

export const deleteRole = async (id, page, pageSize, setPaginationModel) => {
  try {
    setIsLoading(true);
    const rolesRequestData = getRolesData();
    await rolesAPI.delete(`${rolesRequestData.url}/${id}`);
    toast.success("Role deleted successfully");
    await fetchRoles(page, pageSize, setRoles, setPaginationModel);
  } catch (error) {
    console.error(error?.message);
    toast.error(error?.response?.data?.error?.message || error?.message);
  } finally {
    setIsLoading(false);
  }
};
